var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "add"
  }, [_c('router-link', {
    staticClass: "add-btn-cls",
    attrs: {
      "to": {
        name: 'planBookDetail'
      }
    }
  }, [_vm._v("新增")])], 1), _c('div', {
    staticStyle: {
      "height": "20px"
    }
  }), _c('div', {
    staticClass: "table-div"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _vm._l(_vm.planList, function (planItem, index) {
    return _c('tbody', {
      key: index
    }, [_vm._m(1, true), _c('tr', {
      staticClass: "head"
    }, [_c('td', {
      staticStyle: {
        "color": "gray"
      },
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v(_vm._s(planItem.createTime))]), _c('td', {
      attrs: {
        "colspan": "2"
      }
    }, [_c('span', {
      staticStyle: {
        "color": "gray"
      }
    }, [_vm._v("计划单号:")]), _vm._v(_vm._s(planItem.orderNo))]), _c('td', {
      attrs: {
        "colspan": "4"
      }
    })]), _c('tr', [_c('td', {
      staticClass: "detail",
      attrs: {
        "colspan": "4"
      }
    }, [_c('div', {
      staticClass: "ff"
    }, [planItem.imageList.length > 0 ? _c('div', {
      directives: [{
        name: "viewer",
        rawName: "v-viewer"
      }],
      staticClass: "file images"
    }, _vm._l(planItem.imageList, function (file) {
      return _c('img', {
        key: file,
        attrs: {
          "src": file
        }
      });
    }), 0) : _vm._e(), planItem.fileList.length > 0 ? _c('div', {
      staticClass: "file"
    }, _vm._l(planItem.fileList, function (file, i) {
      return _c('span', [_c('a', {
        attrs: {
          "href": "javascript:void(0)"
        },
        on: {
          "click": function click($event) {
            return _vm.openExcel(file);
          }
        }
      }, [_vm._v("excel文档" + _vm._s(i + 1))])]);
    }), 0) : _vm._e(), _c('div', {
      staticClass: "remark"
    }, [_vm._v(" " + _vm._s(planItem.remarks) + " ")])])]), _c('td', [_vm._v(_vm._s(planItem.statusName))]), _c('td', [_vm._v(_vm._s(planItem.handleTime))]), _c('td', [_vm._v(_vm._s(planItem.handleResult))]), _c('td', [planItem.status > 1 ? _c('router-link', {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        "to": {
          name: 'planBookDetail',
          params: {
            id: planItem.planId
          }
        }
      }
    }, [_vm._v("详情")]) : _c('router-link', {
      staticStyle: {
        "margin-right": "5px"
      },
      attrs: {
        "to": {
          name: 'planBookDetail',
          params: {
            id: planItem.planId
          }
        }
      }
    }, [_vm._v("编辑")]), planItem.status <= 2 ? _c('a', {
      attrs: {
        "href": "javascript:void(0);"
      },
      on: {
        "click": function click($event) {
          return _vm.cancelPlan(planItem.planId);
        }
      }
    }, [_vm._v("作废")]) : _vm._e()], 1)]), _vm.planList.length < 1 ? _c('tr', [_c('td', {
      attrs: {
        "colspan": "9"
      }
    }, [_vm._v("暂无数据")])]) : _vm._e()]);
  })], 2)]), _vm.planList.length !== 0 ? _c('div', {
    staticClass: "pagination",
    staticStyle: {
      "width": "940px",
      "justify-content": "flex-end",
      "margin-left": "0px"
    }
  }, [_c('b-pagination', {
    staticClass: "is-centered",
    attrs: {
      "total": _vm.total,
      "current": _vm.page,
      "per-page": _vm.pageSize,
      "range-before": 3,
      "range-after": 1
    },
    on: {
      "update:current": function updateCurrent($event) {
        _vm.page = $event;
      },
      "change": _vm.handlePageChange
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tr', {
    staticClass: "table-tr"
  }, [_c('th', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("详情")]), _c('th', [_vm._v("状态")]), _c('th', {
    attrs: {
      "width": "180"
    }
  }, [_vm._v("处理时间")]), _c('th', [_vm._v("处理结果")]), _c('th', [_vm._v("操作")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('tr', {
    staticClass: "sep"
  }, [_c('td', {
    attrs: {
      "colspan": "8"
    }
  })]);
}];
export { render, staticRenderFns };